var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.refName,staticClass:"drag-resize-box",class:{'drag-resize-box-disabled': _vm.disabled},style:({
    width: _vm.w + 'px',
    height: _vm.h + 'px',
    left: _vm.x + 'px',
    top: _vm.y + 'px',
    zIndex: _vm.z + '',
    border: _vm.showBorder ? '1px solid ' + _vm.bgColor : '0',
  })},[_c('div',{staticClass:"drag-resize-content",style:({ overflow: !_vm.disabled ? 'auto' : 'hidden', background: _vm.bgColor }),on:{"mousedown":function($event){return _vm.mouseDown($event, 'move')}}},[_vm._t("default")],2),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-toolbar",on:{"mousedown":function($event){$event.preventDefault();return _vm.mouseDown($event, 'move')}}},[(_vm.showClose)?_c('div',{staticClass:"icon-close",on:{"click":function($event){$event.preventDefault();return _vm.closeComp.apply(null, arguments)}}}):_vm._e()]):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-lt",class:[_vm.showBorder ? 'b-show' : 'b-hide'],on:{"mousedown":function($event){return _vm.mouseDown($event, 'nw')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-t",on:{"mousedown":function($event){return _vm.mouseDown($event, 'n')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-rt",class:[_vm.showBorder ? 'b-show' : 'b-hide'],on:{"mousedown":function($event){return _vm.mouseDown($event, 'ne')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-r",on:{"mousedown":function($event){return _vm.mouseDown($event, 'e')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-rb",class:[_vm.showBorder ? 'b-show' : 'b-hide'],on:{"mousedown":function($event){return _vm.mouseDown($event, 'se')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-b",on:{"mousedown":function($event){return _vm.mouseDown($event, 's')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-lb",class:[_vm.showBorder ? 'b-show' : 'b-hide'],on:{"mousedown":function($event){return _vm.mouseDown($event, 'sw')}}}):_vm._e(),(_vm.showToolbar && !_vm.disabled)?_c('div',{staticClass:"dr-re-l",on:{"mousedown":function($event){return _vm.mouseDown($event, 'w')}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }